import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import IframeForm from "../components/IframeForm/IframeForm"

const AboutPage = () => (
  <Layout>
    <SEO 
      title="Inquire" 
      keywords="translation, localisation, localization, English to Tagalog, Tagalog to English, cultural translation, cultural naturalization, cultural naturalisation, production management, voice acting, voice actors, voice actor management, dubbing, voice over, Australia, Philippines, Sleigh Platform, subtitles, subtitling, Synchresis, language services"
    />
        <IframeForm iframe='<iframe src="https://www.cognitoforms.com/f/t9o-8CFsP0qOlWtp0D9pyw?id=26" style="position:relative;width:1px;min-width:100%;*width:100%;" frameborder="0" scrolling="yes" seamless="seamless" height="665" width="100%"></iframe>'/>
  </Layout>
)

export default AboutPage
